import axios from "axios";
import { ethers } from "ethers";

export const fetchDogeUsdRate = async () => {
  const url =
    "https://api.coingecko.com/api/v3/simple/price?ids=dogecoin&vs_currencies=usd";

  const res = await axios.get(url);
  const data = res?.data ?? {};

  const rate = data["dogecoin"] ? data["dogecoin"].usd : 0;

  return rate;
};

export const dogeToUsd = ({ dogeUsdRate, dogeAmount }) =>
  (Number(dogeAmount) * Number(dogeUsdRate)).toFixed(2);

export const usdToDoge = ({ dogeUsdRate, usdAmount }) =>
  (Number(usdAmount) / Number(dogeUsdRate)).toFixed(3);

export const fetchDogeAmountFromUsdAmount = async (usdAmount) => {
  const dogeUsdRate = await fetchDogeUsdRate();
  return usdToDoge({ dogeUsdRate, usdAmount });
};

export const fetchUsdAmountFromDogeAmount = async (dogeAmount) => {
  const dogeUsdRate = await fetchDogeUsdRate();
  return dogeToUsd({ dogeUsdRate, dogeAmount });
};

export const weiFromDoge = (dogeAmount) => {
  return ethers.utils.parseEther(String(dogeAmount)).toString();
};

export const dogeFromWei = (weiAmount) => {
  return ethers.utils.formatEther(String(weiAmount));
};
