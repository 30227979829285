import '../App.css'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import React from 'react';
import QRCode from 'qrcode';
import socketIO from "socket.io-client";
import icon from '../images/dogeIcon.png';
import { fetchDogeUsdRate, dogeToUsd, usdToDoge } from "../utils/price";

export function Tip() {
    var items = {
        items: [{name: 'Brooklyn Slice', color: 'pink', price_cents: 332}], 
        page: 0, 
    };
    return(
        <div className="App">
				<Container style={{backgroundColor: 'black', height: '800px', color: 'white'}}>
					<Row style={{height: '120px'}} />

					<Row>
						<div style={{color: 'white', fontSize: '36px', textAlign: 'center'}}>Balance Due $</div>
						<div style={{color: 'white', fontSize: '30px', textAlign: 'center'}}>Would you like to leave a tip?</div>
					</Row>
					<Row style={{height: '56px'}} />

					<Row>
						<Col sm={{span: 1}}/>
						<Col sms={{span: 10}}>
							<Row style={{flexWrap: 'nowrap'}}>
								<Col style={{padding: '5px', margin: '0px', fontSize: '36px', textAlign: 'center', height: '100px', borderRadius: '4px'}} sm={{span: 4}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col style={{fontSize: '36px', textAlign: 'center'}}>25%</Col></Row>
										<Row><Col style={{fontSize: '21px', textAlign: 'center'}}>total * 1.25</Col></Row>
									</div>
								</Col>
								<Col style={{padding: '5px', margin: '0px', fontSize: '36px', textAlign: 'center', height: '100px', borderRadius: '4px'}} sm={{span: 4}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col style={{fontSize: '36px', textAlign: 'center'}}>20%</Col></Row>
										<Row><Col style={{fontSize: '21px', textAlign: 'center'}}>total * 1.20</Col></Row>
									</div>
								</Col>
								<Col style={{padding: '5px', margin: '0px', fontSize: '36px', textAlign: 'center', height: '100px', borderRadius: '4px'}} sm={{span: 4}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col style={{fontSize: '36px', textAlign: 'center'}}>18%</Col></Row>
										<Row><Col style={{fontSize: '21px', textAlign: 'center'}}>total * 1.18</Col></Row>
									</div>
								</Col>
							</Row>
						</Col>
						<Col sm={{span: 1}}/>
					</Row>

					<Row>
						<Col sm={{span: 1}} />
						<Col sms={{span: 10}}>
							<Row>
								<Col style={{padding: '5px', margin: '0px', fontSize: '21px', height: '100px', textAlign: 'center', lineHeight: '100px'}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col>Custom tip</Col></Row>
									</div>
								</Col>
								<Col style={{padding: '5px', margin: '0px', fontSize: '21px', height: '100px', textAlign: 'center', lineHeight: '100px'}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col>No tip</Col></Row>
									</div>
								</Col>
							</Row>
						</Col>
						<Col sm={{span: 1}} />
					</Row>

					<Row style={{height: '200px'}} />

					<Row style={{height: '50px', lineHeight: '50px'}}>
						<Col sm={{span: 1}} />
						<Col sm={{span: 10}} style={{textAlign: 'center', backgroundColor: '#169EDD', color: 'black'}}  >Continue</Col>
						<Col sm={{span: 1}} />
					</Row>
				</Container>
		  
		
                </div>
    );
}

export default Tip;