import '../App.css'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import React from 'react';
import QRCode from 'qrcode';
import socketIO from "socket.io-client";
import icon from '../images/dogeIcon.png';
import { fetchDogeUsdRate, dogeToUsd, usdToDoge } from "../utils/price";

export function Order() {
    var items = {
        items: [{name: 'Brooklyn Slice', color: 'pink', price_cents: 332}], 
        page: 0, 
    };
    return(
        <div className="App">
				<Container>

					<Row>
						<Col>
							<div style={{backgroundColor: 'black', height: '50px'}}>
							</div>
						</Col>
					</Row>

					<Row style={{columnGap: 0}}>
						<Col sm={{ span: 5 }} style={{paddingRight: 0}}>
							<div style={{height: '800px', backgroundColor: 'grey', padding: '15px'}}>

								<Row style={{backgroundColor: '#dfe0df'}}>
									<Col style={{textAlign: 'left'}} sm={{ span: 3 }}>Emp Id: 40</Col>
									<Col sm={{span: 6}} />
									<Col sm={{ span: 3 }}><strong>Jane Smith</strong></Col>
								</Row>

								<Row style={{backgroundColor: 'white', borderBottom: 'solid 1px'}}>
									<Col style={{color: '#149edc', textAlign: 'left'}} sm={{ span: 6 }}>Name</Col>
									<Col style={{color: '#149edc'}} span={2}>Qty</Col>
									<Col style={{color: '#149edc'}} span={2}>Each</Col>
									<Col style={{color: '#149edc'}} span={2}>Total</Col>
								</Row>

								<Row style={{backgroundColor: 'white', height: '400px', borderBottom: 'solid 1px'}}><Col>
								{
									// items.map((item, index) => (
									// 	<Row style={{backgroundColor: 'white'}} key={`cart-${index}`}>
									// 		<Col style={{textAlign: 'left'}} sm={{ span: 6 }}>{item.name}</Col>
									// 		<Col span={2}>1</Col>
									// 		<Col span={2}>${(item.price_cents / 100).toFixed(2)}</Col>
									// 		<Col span={2}>${(item.price_cents / 100).toFixed(2)}</Col>
									// 	</Row>
									// ))
								}
								</Col></Row>

								<Row style={{backgroundColor: '#dfe0df', height: '300px'}}>
									<Col>
										<Row>
											<Col sm={{ span: 7}}>
												<Row>
													<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}} sm={{span: 3}}>Tab</Col>
													<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}} sm={{span: 3}}>+ Item</Col>
													<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}} sm={{span: 3}}>Split</Col>
												</Row>
											</Col>

											<Col sm={{ span: 1 }} />

											<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}}>Cancel</Col>
											<Col style={{backgroundColor: '#149edc', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px', color: 'white'}}>Send</Col>

										</Row>

										<Row style={{height: '175px'}}>
											<Col sm={{ span: 6}}>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Credits:</strong></Col><Col>$0.00</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Discounts:</strong> </Col><Col>$0.00</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}>Tips (0%): </Col><Col>$0.00</Col>
												</Row>
											</Col>
											<Col sm={{ span: 6}}>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Subtotal:</strong> </Col><Col>${
														// this.total().toFixed(2)
													}</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Tax:</strong> </Col><Col>$0.29</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Balance Due:</strong> </Col><Col style={{color: 'red'}}>${
														// (0.29 + this.total()).toFixed(2)
													}</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}>TOTAL: </Col><Col style={{color: '#149edc'}}>${
														// (0.29 + this.total()).toFixed(2)
													}</Col>
												</Row>
											</Col>
										</Row>

										<Row>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Guests</Col>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Discnt</Col>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Dine In</Col>
											<Col style={{backgroundColor: '#149edc', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px', color: 'white'}}>Fast ($)</Col>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Print</Col>
											<Col style={{backgroundColor: '#149edc', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px', color: 'white'}} >Pay ($)</Col>
										</Row>

									</Col>
								</Row>

							</div>
						</Col>

						<Col sm={{ span: 7 }} style={{paddingLeft: 0}}>
							<div style={{height: '800px', backgroundColor: '#D1D1D0'}}>
                            <Row style={{padding: '10px'}}>
						{
							[
								{name: 'Food ', color: '#169EDD' },
								{name: 'Soda Counter ', color: '#FF1732' },
								{name: 'Online Menu ', color: '#169EDD' },
								{name: 'Gift Cards', color: '#D1D1D0' },
							].map((item, index) => (
								<Col style={{
									fontSize: '14px',
									boxShadow: '0px 0px 4px #000000DB', 
									borderRadius: '4px', margin: '5px', 
									height: '75px', lineHeight: '75px', 
									backgroundColor: item.color, color: `${index == 3 ? 'black' : 'white'}`}} sm={{ span: 2 }}>{item.name}</Col>
							))
						}
					</Row>
	<hr/>
					<Row style={{padding: '10px'}}>
						{
							[
								{name: 'Pizza by the Slice', color: '#0053C4' },
								{name: 'Pizza Pies', color: '#C31A0F' },
								{name: 'Salads', color: '#029100' },
								{name: 'Heroes & Slides', color: '#AF3A00' },
							].map((item, index) => (
								<Col style={{
									fontSize: '12px',
									boxShadow: '0px 0px 4px #000000DB', 
									borderRadius: '4px', margin: '5px', 
									height: '75px', lineHeight: '75px', 
									backgroundColor: item.color, color: 'white'}} sm={{ span: 2 }}>{item.name}</Col>
							))
						}
					</Row>
	<hr/>
					<Row style={{padding: '10px'}}>
						{
							[
								{name: 'Pepperoni Slice', color: 		'#F78A9B', price_cents: 301},
								{name: 'Brooklyn Slice', color: 		'#F78A9B', price_cents: 332},
								{name: 'Margherita Slice', color: 	'#F78A9B', price_cents: 432},
								{name: 'Sophia Loren Slice', color: '#F78A9B', price_cents: 532},
								{name: 'Bianco Slice', color: 			'#F78A9B', price_cents: 442},

								{name: 'Grandma Slice', color: '#7fc6ff', price_cents: 632},
								{name: 'Cup & Char Slice', color: '#7fc6ff', price_cents: 232},
								{name: 'Tartufo Slice', color: '#7fc6ff', price_cents: 330},
								{name: 'Paesano Slice', color: '#7fc6ff', price_cents: 220},
								{name: 'Calabrese Slice', color: '#7fc6ff', price_cents: 440},
								{name: 'Apple Bacon Slice', color: '#7fc6ff', price_cents: 550},

								{name: 'Vegetarian Slice', color: '#FFFFFF', price_cents: 230},
								{name: 'Vegan Slice', color: '#0FE510', price_cents: 320},
								{name: 'Vegan Pepperoni', color: '#0FE510', price_cents: 420},
								{name: 'Garlic knots', color: '#FF7F00', price_cents: 540},
								{name: 'Chocolate chip', color: '#30009C', price_cents: 450},
								{name: 'Add Topping', color: '#FFFFFF', price_cents: 330},
								{name: 'TOO GOOD TO GO', color: '#FEE7E7', price_cents: 650},
							].map((content, index) => (
								<Col key={`menu-${index}`} style={{
									border: '1px solid', borderRadius: '4px', margin: '5px', height: '75px', lineHeight: '75px', fontSize: '11px', backgroundColor: content.color
								}} sm={{span: 2}} data-price={content.price_cents}>{content.name}</Col>
							))
						}
					</Row>
							</div>
						</Col>
					</Row>

				</Container>
		  
		
                </div>
    );
}

export default Order;