import '../App.css'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import React from 'react';
import QRCode from 'qrcode';
import socketIO from "socket.io-client";
import icon from '../images/dogeIcon.png';
import { fetchDogeUsdRate, dogeToUsd, usdToDoge } from "../utils/price";

export function Pay() {
    var items = {
        items: [{name: 'Brooklyn Slice', color: 'pink', price_cents: 332}], 
        page: 0, 
    };
    return(
        <div className="App">
				<Container>

					<Row>
						<Col>
							<div style={{backgroundColor: 'black', height: '50px'}}>
							</div>
						</Col>
					</Row>

					<Row style={{columnGap: 0}}>
						<Col sm={{ span: 5 }} style={{paddingRight: 0}}>
							<div style={{height: '800px', backgroundColor: 'grey', padding: '15px'}}>

								<Row style={{backgroundColor: '#dfe0df'}}>
									<Col style={{textAlign: 'left'}} sm={{ span: 3 }}>Emp Id: 40</Col>
									<Col sm={{span: 6}} />
									<Col sm={{ span: 3 }}><strong>Jane Smith</strong></Col>
								</Row>

								<Row style={{backgroundColor: 'white', borderBottom: 'solid 1px'}}>
									<Col style={{color: '#149edc', textAlign: 'left'}} sm={{ span: 6 }}>Name</Col>
									<Col style={{color: '#149edc'}} span={2}>Qty</Col>
									<Col style={{color: '#149edc'}} span={2}>Each</Col>
									<Col style={{color: '#149edc'}} span={2}>Total</Col>
								</Row>

								<Row style={{backgroundColor: 'white', height: '400px', borderBottom: 'solid 1px'}}><Col>
								{
									// items.map((item, index) => (
									// 	<Row style={{backgroundColor: 'white'}} key={`cart-${index}`}>
									// 		<Col style={{textAlign: 'left'}} sm={{ span: 6 }}>{item.name}</Col>
									// 		<Col span={2}>1</Col>
									// 		<Col span={2}>${(item.price_cents / 100).toFixed(2)}</Col>
									// 		<Col span={2}>${(item.price_cents / 100).toFixed(2)}</Col>
									// 	</Row>
									// ))
								}
								</Col></Row>

								<Row style={{backgroundColor: '#dfe0df', height: '300px'}}>
									<Col>
										<Row>
											<Col sm={{ span: 7}}>
												<Row>
													<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}} sm={{span: 3}}>Tab</Col>
													<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}} sm={{span: 3}}>+ Item</Col>
													<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}} sm={{span: 3}}>Split</Col>
												</Row>
											</Col>

											<Col sm={{ span: 1 }} />

											<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}}>Cancel</Col>
											<Col style={{backgroundColor: '#149edc', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px', color: 'white'}}>Send</Col>

										</Row>

										<Row style={{height: '175px'}}>
											<Col sm={{ span: 6}}>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Credits:</strong></Col><Col>$0.00</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Discounts:</strong> </Col><Col>$0.00</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}>Tips (0%): </Col><Col>$0.00</Col>
												</Row>
											</Col>
											<Col sm={{ span: 6}}>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Subtotal:</strong> </Col><Col>${
														// this.total().toFixed(2)
													}</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Tax:</strong> </Col><Col>$0.29</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Balance Due:</strong> </Col><Col style={{color: 'red'}}>${
														// (0.29 + this.total()).toFixed(2)
													}</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}>TOTAL: </Col><Col style={{color: '#149edc'}}>${
														// (0.29 + this.total()).toFixed(2)
													}</Col>
												</Row>
											</Col>
										</Row>

										<Row>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Guests</Col>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Discnt</Col>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Dine In</Col>
											<Col style={{backgroundColor: '#149edc', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px', color: 'white'}}>Fast ($)</Col>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Print</Col>
											<Col style={{backgroundColor: '#149edc', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px', color: 'white'}} >Pay ($)</Col>
										</Row>

									</Col>
								</Row>

							</div>
						</Col>

						<Col sm={{ span: 7 }} style={{paddingLeft: 0}}>
							<div style={{height: '800px', backgroundColor: '#D1D1D0'}}>
                            <Row>
					<Col sm={{span: 2}} />

					<Col>
						<Row style={{height: '40px', lineHeight: '40px'}}>
							<Col style={{fontSize: '14px', paddingLeft: '0', textAlign: 'left'}} sm={{span: 3}}><strong>Balance due</strong></Col>
							<Col style={{fontSize: '14px', paddingRight: '0', textAlign: 'right'}} sm={{span: 9}}><strong>Amount tendered</strong></Col>
						</Row>

						<Row style={{paddings: '20px'}}>
							<Col sm={{span: 3}} style={{
								backgroundColor: '#a8a9a8', 
								borderRadius: '4px', 
								height: '50px', lineHeight: '50px', 
								border: '1px solid'}}>total</Col>
							<Col sm={{span: 9}} style={{paddingRight: '0'}}><input style={{width: '100%'}} /></Col>
						</Row>

						<Row style={{height: '10px'}} />

						{
							[[1,2,3, '$26'],[4,5,6, '$30'],[7,8,9, '$40'],['C',0,'00', '<DEL']].map((row) => (
									<Row>
										{
											row.map((b, index) => (
												<Col style={
													{
														boxShadow: '0px 0px 4px #000000DB', 
														backgroundColor: `${index % 4 == 3 ? '#169EDD' : 'white'}`, 
														color: `${index % 4 == 3 ? 'white' : 'black'}`,
														margin: '2px', 
														borderRadius: '4px', 
														height: '132px', 
														width: '75px',
														lineHeight: '132px'}
												} >{b}</Col>
											))
										}
									</Row>
								)
							)
						}

					</Col>

					<Col sm={{span: 1}} />

					<Col sm={{span: 3}}>
						{
							[
								'Cash',
								'Credit',
								'Gift card',
								'DogeExpress',
								'Other',
							].map((method, index) => (
								<Row><Col style={{
									backgroundColor: '#a8a9a8', 
									borderRadius: '4px', 
									border: '1px solid', marginTop: '5px', marginLeft: '5px', height: '50px', lineHeight: '50px'}} sm={{span: 10}}
									// onClick={this.handleClickDoge}
									>
										{ index == 3 ? <img src={icon} style={{width: '20px', height: '20px', marginRight: '5px'}} /> : null}
										{method}
									</Col></Row>
							))
						}
					</Col>
				</Row>
							</div>
						</Col>
					</Row>

				</Container>
		  
		
                </div>
    );
}

export default Pay;