import '../App.css'
import { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import React from 'react';
import QRCode from 'qrcode';
import socketIO from "socket.io-client";
import icon from '../images/dogeIcon.png';
import { fetchDogeUsdRate, dogeToUsd, usdToDoge } from "../utils/price";

export function DogePay() {
    var items = {
        items: [{name: 'Brooklyn Slice', color: 'pink', price_cents: 332}], 
        page: 0, 
    };

    const [total, setTotal] = useState(3.33);
    const [dogeUSDRate, setDogeUSDRate] = useState(0);

    const getDogeUSDRate = async () => {
		const rate = await fetchDogeUsdRate();
		// console.log("rate: ", parseFloat(rate));
		// console.log("total: ", this.total().toFixed(2));

		const newRate = parseFloat(rate);
        if(newRate != null) {
            setDogeUSDRate(newRate);
        }
		// const totalDogecoin = (this.total().toFixed(2)) / newRate;
		// console.log("totalDogecoin: ", totalDogecoin);
		return newRate;
	}

    useEffect(() => {
        getDogeUSDRate();
        console.log("dogeUSDRate: ", dogeUSDRate);
        // setDogeUSDRate(dogeUSDRateValue);
    }, [dogeUSDRate]);


    return(
        <div className="App">
				<Container style={{backgroundColor: 'black', height: '800px', color: 'white'}}>
					<Row>
						<Col sm={{span: 5}}>
					 		<Row style={{backgroundColor: '#4189C7', height: '500px', margin: '10px', borderRadius: '4px'}}><Col>
								<Row style={{height: '60px'}} />
								{
									// this.state.items.map((item, index) => (
									// 	<Row key={`cart-${index}`}>
									// 		<Col style={{textAlign: 'left'}} sm={{ span: 10 }}><strong>{item.name}</strong></Col>
									// 		<Col style={{textAlign: 'right'}} span={2}>${(item.price_cents / 100).toFixed(2)}</Col>
									// 	</Row>
									// ))
								}
								<Row>
									<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>- DogeExpress Discount</Col>
									<Col style={{textAlign: 'right'}} span={2}>- $0.33</Col>
								</Row>
							</Col></Row>

					 		<Row style={{backgroundColor: '#4189C7', height: '200px', margin: '10px', borderRadius: '4px'}}>
								<Col>

									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Subtotal:</Col>
										<Col style={{textAlign: 'left'}} span={1}>${total}</Col>
									</Row>
								
									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Tax:</Col>
										<Col style={{textAlign: 'left'}} span={1}>$0.33</Col>
									</Row>
								
									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Tip:</Col>
										<Col style={{textAlign: 'left'}} span={1}>$0.33</Col>
									</Row>
								
									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Balance due:</Col>
										<Col style={{textAlign: 'left'}} span={1}>${total + 0.33 + 0.33}</Col>
									</Row>

									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Dogecoin due:</Col>
										<Col style={{textAlign: 'left'}} span={1}>{(total + 0.33 + 0.33) / dogeUSDRate.toFixed(2)}</Col>
									</Row>
								
								</Col>
							</Row>
						</Col>

						<Col sm={{span: 7}}>
								<Row style={{height: '120px'}} />

								<Row style={{fontSize: '30px', textAlign: 'center'}}>
									<Col><img src={icon} style={{width: '44px', height: '44px'}} /> DogeExpress payment</Col>
								</Row>

								<Row style={{height: '100px'}} />

								<Row style={{textAlign: 'center'}}>
									<Col>Please scan this code to complete payment</Col>
								</Row>

								<Row style={{height: '50px'}} />

								<Row style={{textAlign: 'center'}}>
									<Col>
										{/* <img src={this.state.qrCodeUrl} /> */}
									</Col>
								</Row>

								<Row style={{height: '50px'}} />

								<Row style={{textAlign: 'center'}}>
									<Col sm={{span: 2}} />
									<Col>Send only Dogecoin (DOGE) to this address. Sending any other coins may result in permanent loss.</Col>
									<Col sm={{span: 2}} />
								</Row>
						</Col>
					</Row>
				</Container>
		  
		
                </div>
    );
}

export default DogePay;